import "./applyAnimBtn.css"
import applybtn from "../images/btn/applybtn.svg"
import pension from "../images/btn/pension.png"
// import { motion } from "framer-motion";

const ApplyAnimBtn = ({ open }) => {
  return (
    <button className="applyAnimBtn" onClick={open}>
      <img src={pension} alt="" />
      <span>立即申請</span>
    </button>
  )
}

export default ApplyAnimBtn
