import ReactChatbot from "./chatbot/ReactChatbot"
import FbBtn from "./FbBtn"
import "./floatBtnlist.css"
import PhotoBtn from "./FloatingBtn/PhotoBtn"
import WhatsappBtn from "./WhatsappBtn"

const FloatBtnList = ({ data }) => {
  const phone = data?.MT.find((item) => item.title === "phone")?.description
  const whatsapp = data?.MT.find((item) => item.title === "whatsapp")
    ?.description
  const facebook = data?.MT.find((item) => item.title === "facebook")
    ?.description
  return (
    <div className="floatBtnList">
      <div className="floatBtnList-item">
        <PhotoBtn phone={phone} />
      </div>
      <div className="floatBtnList-item">
        <WhatsappBtn phone={whatsapp} />
      </div>
      <div className="floatBtnList-item">
        <FbBtn url={facebook} />
      </div>
      {/* <div className="floatBtnList-item">
        <ReactChatbot />
      </div> */}
    </div>
  )
}

export default FloatBtnList
