import React, { useState } from "react"
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import useFetch from "./codeHelper/useFetch"
import AboutUs from "./AboutUs"
import NavBar from "./component/NavBar"
import Home from "./Home"
import HotTopics from "./HotTopics"
import FloatBtnList from "./component/FloatBtnList"
import Thankyou from "./Thankyou"
import TU from "./LoanServicesPages/TU"
import SmeLoan from "./LoanServicesPages/SmeLoan"
import PrivateLending from "./LoanServicesPages/PrivateLending"
import Mortgage from "./LoanServicesPages/Mortgage"
import Creditcard from "./LoanServicesPages/Creditcard"
import OnlineLoan from "./LoanServicesPages/OnlineLoan"

import "react-toastify/dist/ReactToastify.min.css"

import ApplicationBtn from "./component/ApplicationBtn"
import Disclaimer from "./Disclaimer"
import Ordinance from "./Ordinance"
import ReactChatbot from "./component/chatbot/ReactChatbot"
import { ENV } from "./config"
import Application from "./component/Application/Application"
import { Helmet } from "react-helmet"
import GoogleTagManager from "./component/GoogleTagManager"
import GoogleAdsConversion from "./component/GoogleAdsConversion"

function App() {
  if (!window.dataLayer) {
    window.dataLayer = []
  }
  const { data } = useFetch(ENV + "/api/v1/content")
  const post_result = useFetch(ENV + "/api/v1/post")
  const [showBottomNav, setShowBottomNav] = useState(true)
  const gtm_id = data?.MT?.find((item) => item.title === "GtagID")?.description
  const gtm_label = data?.MT?.find((item) => item.title === "GtagLabel")
    ?.description

  console.log("gtm_id", gtm_id)
  console.log("gtm_label", gtm_label)

  return (
    <BrowserRouter>
      <div className="App">
        <GoogleTagManager id={gtm_id} />
        <GoogleAdsConversion id={gtm_id} label={gtm_label} />

        <ReactChatbot />
        <FloatBtnList data={data} />
        {showBottomNav && (
          <ApplicationBtn
            isBottomNav={true}
            setShowBottomNav={setShowBottomNav}
          />
        )}
        <NavBar content={data} postData={post_result.data} />
        <div className="top-space"></div>
        <Routes>
          <Route exact path="/" element={<Home content={data} />} />
          <Route
            exact
            path="/application/:content"
            element={
              <Application setShowBottomNav={setShowBottomNav} data={data} />
            }
          />
          <Route exact path="/aboutus" element={<AboutUs content={data} />} />
          <Route
            exact
            path="/onlineLoan"
            element={
              <OnlineLoan content={data?.LoanServicePage} metaData={data} />
            }
          />
          <Route
            exact
            path="/creditcard"
            element={
              <Creditcard content={data?.LoanServicePage} metaData={data} />
            }
          />
          <Route
            exact
            path="/mortgage"
            element={
              <Mortgage content={data?.LoanServicePage} metaData={data} />
            }
          />
          <Route
            exact
            path="/privateLending"
            element={
              <PrivateLending content={data?.LoanServicePage} metaData={data} />
            }
          />
          <Route
            exact
            path="/smeloan"
            element={
              <SmeLoan content={data?.LoanServicePage} metaData={data} />
            }
          />
          <Route
            exact
            path="/tu"
            element={<TU content={data?.LoanServicePage} metaData={data} />}
          />

          <Route
            exact
            path="/hottopics/:order/:title"
            element={<HotTopics content={data} postData={post_result.data} />}
          />

          <Route exact path="/thankyou" element={<Thankyou content={data} />} />
          <Route
            exact
            path="/disclaimer"
            element={<Disclaimer content={data} />}
          />
          <Route
            exact
            path="/ordinance"
            element={<Ordinance content={data} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
