export function FormDatatext(text) {
  const newText = String(text)
    .split("\n")
    .map((str, indx) => (
      <div key={indx}>
        <span>{str}</span>
        <br />
      </div>
    ))
  return newText
}

export function formatPhoneNumber(number) {
  if (!number || typeof number !== "string") {
    return "" // or return a default value or handle this case differently
  }
  return number.slice(0, 4) + " " + number.slice(4)
}
