import { useState, useEffect } from "react"
import "./calculatorContent.css"
import NumberSlider from "./NumberSlider"
import ApplicationBtn from "./ApplicationBtn"
import { FormDatatext } from "../codeHelper/useFormatDatatext"

const CalculatorContent = ({ content }) => {
  const [amount, setAmount] = useState(0)
  const [principal, setPrincipal] = useState(0)
  const [period, setPeriod] = useState(0)
  const [monthlyPay, setMonthlyPay] = useState(0)

  let calculatorData = content?.application.find(
    (cal) => cal.title === "calculator"
  )

  const HandleAmount = (value) => {
    setPrincipal(value)
    FormatMoney(value)
  }

  const FormatMoney = (value) => {
    setAmount(value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
  }

  useEffect(() => {
    let P = principal
    let t = period
    let i = 0.3 / 12
    let result = 0
    result = (P * i * (1 + i) ** t) / ((1 + i) ** t - 1)

    if (P === 0 || t === 0) {
      setMonthlyPay("")
    } else {
      setMonthlyPay("$" + Math.round(result))
    }
  }, [principal, period])

  return (
    <div className="container  caltor">
      <h2 className="color1">還款計數機</h2>
      <div className="caltor-item">
        <p>貸款金額</p>
        <p>${amount}</p>
      </div>

      <NumberSlider
        max_value={500000}
        step_value={1000}
        setChangedValue={HandleAmount} //setChangedValue get value from NumberSlider,HandleAmountFormat format value
      />

      <div className="caltor-item">
        <p>還款期</p>
        <p>{period} 個月</p>
      </div>

      <NumberSlider max_value={60} step_value={6} setChangedValue={setPeriod} />
      <h3>每期還款額：{monthlyPay}</h3>

      <div className="caltor-apply-container">
        <div className="caltor-appy-btn">
          <ApplicationBtn isAnimBtn={true} />
        </div>
        <div className="statement mt-5">
          {FormDatatext(calculatorData?.description)}
        </div>
      </div>
    </div>
  )
}

export default CalculatorContent
