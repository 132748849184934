import "./fbBtn.css"
import fbBtn from "../images/icon/facebook.png"
const FbBtn = ({ url }) => {
  return (
    <div className="fbBtn">
      <a href={url}>
        <img src={fbBtn} alt="" />
      </a>
    </div>
  )
}

export default FbBtn
