import "./homeSection.css"
import man from "../images/home/home_caltor_man.png"
import calculatorBox from "../images/home/home_calculatorBox.png"
import CalculatorContent from "../component/CalculatorContent"

const HomeSection2 = ({ content }) => {
  return (
    <div className="home-section2">
      <div className="calculator-container">
        {/* <img className="calculator-bg" src={calculatorBox} alt="" /> */}
        <div className="calculator">
          <CalculatorContent content={content} />
        </div>
      </div>
      <img className="calculator-man" src={man} alt="" />
    </div>
  )
}

export default HomeSection2
