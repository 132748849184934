// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKqoIkMHelmkf5eSzYbazRjSmQxHhfNMg",
  authDomain: "rm-project-56219.firebaseapp.com",
  projectId: "rm-project-56219",
  storageBucket: "rm-project-56219.appspot.com",
  messagingSenderId: "180134465879",
  appId: "1:180134465879:web:56b8ac9f911fffc2f02e12",
  measurementId: "G-R137CD4MY8",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app);

export const auth = getAuth(app)
export default app
