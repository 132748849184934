import { useEffect } from "react"
import Banner from "./component/Banner"
import Footer from "./component/Footer"
import bg from "./images/loanService/loanservice_bg.png"
import { Helmet } from "react-helmet"
const Ordinance = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="ordinance">
      <Helmet>
        <title>{content?.MT[11].metaTitle}</title>
        <meta name="description" content={content?.MT[11].metaDescription} />
        <meta name="keywords" content={content?.MT[11].metaKeywords} />
      </Helmet>
      <Banner name={"放債人條例"} name_ch={""} bg_url={bg} />
      <div className="container mt-5 mb-5">
        <h1 className="text-center mb-5">放債人條例</h1>
        <h4>《放債人條例》第III部撮要─放債人進行的交易</h4>
        <p>
          本條例第18條列出關於放債人作出貸款的規定。每份貸款協議須以書面訂立，並由借款人於該協議作出後的7天內及於該筆款項貸出之前簽署。在簽訂協議時，須將已簽署的一份協議摘記，連同本撮要一份給予借款人。該摘記須載有該宗貸款的詳盡細則，包括還款條款、保證形式及利率。不符合上述規定的協議不得予以強制執行，除非法庭信納不強制執行該協議並不公平。
        </p>
        <p>
          本條例第19條訂定，如借款人提出書面要求及就有關開支而支付訂明費用，則放債人須將該借款人在貸款協議下當時的債務情況(包括已還款項、到期或即將到期的款項及利率)的結算書正本及副本一份給予借款人。借款人須在該結算書的副本上簽註文字，表示已經收到該結算書的正本，並將經如此簽註的該結算書副本交回該放債人。放債人則須在與該結算書有關的協議持續期間保留該份已交回的結算書副本。如借款人提出書面要求，放債人亦須供給與該宗貸款有關或與保證有關的任何文件的副本。但上述要求，不得在一個月內提出超過一次。放債人如無充分理由而沒有遵照本段所述的要求辦理，則不得收取在該等要求沒有照辦期間的利息。
        </p>
        <p>
          本條例第20條訂定，除非保證人亦是借款人，否則須在協議作出後的7天內，給予保證人一份已簽署的協議摘記、一份保證文書(如有的話)及詳列須支付款項總額的結算書。如保證人在任何時間提出書面要求(不得在一個月內超過一次)，放債人須給予他一份已簽署並詳列已支付款項總額及尚欠款項總額的結算書。放債人如無充分理由而沒有遵照辦理，則不得在該項要求沒有照辦期內強制執行該項保證。
        </p>
        <p>
          本條例第21條訂定，借款人以書面通知後，可隨時將貸款及計算至還款日期為止的利息償還，放債人不得因借款人提早還款而徵收較高利率。
        </p>
        <p>
          放債人如是財政司根據《放債人條例》第33A(4)條以憲報公告認可的放債人或認可的社團的成員，則上述條文不適用。
        </p>
        <p>
          本條例第22條述明，任何貸款協議如訂定須支付複利或訂定不准以分期方式償還貸款，均屬非法。此外，任何貸款協議如訂定到期而未支付的款項須收取較高利率，亦屬非法，但該協議可訂定，未償還的本金部分及利息須收取單利，但利率不得超過在沒有拖欠的情況下須支付的利率；但如法庭信納，該協議如因不符合本條規定而成為非法並不公平，則可宣布該份非法協議全部或部分合法。
        </p>
        <p>
          本條例第23條述明，如放債人在訂立貸款協議時或接受貸款保證時並未領有牌照，則與該放債人訂立的貸款協議及給予他的保證不得強制執行；但如法庭信納，該協議或保證如因本條規定而不能強制執行並不公平，則可宣布該協議或保證的全部或部分可予強制執行。
        </p>
        <h4>《放債人條例》第IV部撮要─過高利率</h4>
        <p>
          本條例第24條釐定任何貸款的最高實際利率為年息48%(“實際利率”須按照本條例附表2計算)任何貸款協議如訂定更高的實際利率，則不得強制執行，而放債人亦可被檢控。此最高利率可由立法會予以變更，但已存在的協議則不受影。對於向繳足款股本不少於$1000000的公司作出的貸款或作出如此貸款的人，本條並不適用。
          (1999年第23號第3條)
        </p>
        <p>
          本條例第25條訂定，在強制執行貸款協議或強制執行貸款保證的法庭法律程序中，或在借款人本人或保證人本人向法庭申請濟助時，法庭可查察該協議的條款，以視該等條款是否極之不公平或利率過高(實際利率如超逾年息48%或立法會所訂的其他利率，即可單憑該理由而推定該利率過高)，而法庭在顧及所有情況後，可將該協議的條款更改，使其對協議各方均公平。對於向繳足款股本不少於$1000000的公司作出的貸款或作出如此貸款的人，本條並不適用。
          (1999年第23號第3條)
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Ordinance
