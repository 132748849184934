import { useEffect } from "react"
import PropTypes from "prop-types"

const GoogleAdsConversion = ({ id, label }) => {
  useEffect(() => {
    if (!id || !label) return

    // 檢查腳本是否已存在
    const existingScript = document.querySelector(
      `script[src="https://www.googletagmanager.com/gtag/js?id=${id}"]`
    )

    if (existingScript) {
      console.log("Google Ads script already loaded.")
      // 发送转化事件，不需要重新加载脚本
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("event", "conversion", {
        send_to: `${id}/${label}`,
      })
      return
    }

    // 加載腳本
    const script = document.createElement("script")
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      // 初始化 gtag
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", id)

      // 发送转化事件
      gtag("event", "conversion", {
        send_to: `${id}/${label}`,
      })
    }
  }, [id, label])

  return null
}

GoogleAdsConversion.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default GoogleAdsConversion
