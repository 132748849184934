import "./footer.css"
import icon from "../images/footerlogo.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { FormDatatext } from "../codeHelper/useFormatDatatext"
import { useEffect, useState } from "react"
import { ENV } from "../config"
import useFetch from "../codeHelper/useFetch"

const Footer = () => {
  const isMobileTablet = useMediaQuery({ query: "(max-width: 1212px)" })

  const { data } = useFetch(ENV + "/api/v1/content")

  const [phone, setPhone] = useState("")
  const [footer, setFooter] = useState("")
  const [address, setAddress] = useState("")
  const [address2, setAddress2] = useState("")
  const [licence, setLicence] = useState("")
  const [hotline, setHotline] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [email, setEmail] = useState("")
  const [faxNumber, setFaxNumber] = useState("")

  useEffect(() => {
    setPhone(data?.MT.find((item) => item.title === "phone")?.description)
    setFooter(data?.MT.find((item) => item.title === "footer")?.description)
    setAddress(data?.MT.find((item) => item.title === "address")?.description)
    setAddress2(data?.MT.find((item) => item.title === "address2")?.description)
    setLicence(data?.MT.find((item) => item.title === "licence")?.description)
    setHotline(data?.MT.find((item) => item.title === "hotline")?.description)
    setWhatsapp(data?.MT.find((item) => item.title === "whatsapp")?.description)
    setEmail(data?.MT.find((item) => item.title === "email")?.description)
    setFaxNumber(
      data?.MT.find((item) => item.title === "faxNumber")?.description
    )
  }, [
    data,
    phone,
    footer,
    address,
    licence,
    hotline,
    whatsapp,
    email,
    faxNumber,
  ])

  function formatPhoneNumber(number) {
    if (!number || typeof number !== "string") {
      return "" // or return a default value or handle this case differently
    }
    return number.slice(0, 4) + " " + number.slice(4)
  }

  const commonLink_list = [
    {
      title: "主頁",
      url: "/",
    },
    {
      title: "關於我們",
      url: "/aboutus",
    },
    {
      title: "免責聲明",
      url: "/disclaimer",
    },
    {
      title: "放債人條例",
      url: "/ordinance",
    },
  ]

  const loanServices_list = [
    {
      title: "免TU貸款",
      url: "/tu",
    },
    {
      title: "物業一按 | 二按",
      url: "/mortgage",
    },
    {
      title: "中小企周轉貸款",
      url: "/smeloan",
    },
    {
      title: "私人借貸",
      url: "/privateLending",
    },
    {
      title: "清卡數及稅貸",
      url: "/creditcard",
    },
    {
      title: "網上貸款",
      url: "/onlineLoan",
    },
  ]

  return (
    <div className="footer">
      {!isMobileTablet && (
        <div className="footer-container">
          <div className="ft1">
            <img className="footer-icon" src={icon} alt="" />
          </div>
          <div className="ft2">
            <div>
              <p>常用連結</p>
              {commonLink_list.map((item, index) => (
                <div key={index} className="mt-3">
                  <HashLink smooth to={item.url}>
                    {item.title}
                  </HashLink>
                </div>
              ))}
            </div>
            <div style={{ marginLeft: "122px" }}>
              <p>貸款服務</p>
              {loanServices_list.map((item, index) => (
                <div key={index} className="mt-3">
                  <HashLink smooth to={item.url}>
                    {item.title}
                  </HashLink>
                </div>
              ))}
            </div>
          </div>
          <div className="ft3">
            <a href={`tel:+852${hotline}`}>
              <p>查詢熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <a href={`https://wa.me/+852${phone}`}>
              <p> Whatsapp：+852 {formatPhoneNumber(whatsapp)}</p>
            </a>
            <p>傳真號碼：{formatPhoneNumber(faxNumber)}</p>
          </div>
          <div className="ft4">
            {address && address2 ? (
              <>
                <p>地址1：{address}</p>
                <p>地址2：{address2}</p>
              </>
            ) : (
              <p>地址：{address || address2}</p>
            )}
            <p>營業時間：10 : 00 - 22 : 00</p>
            <p>電郵: {email}</p>
            <p>Money Lenders Licence: {licence}</p>
          </div>
          <div className="ft5">
            <h4>忠告：借錢梗要還，咪俾錢中介</h4>
            <a href={`tel:+852${hotline}`}>
              <p>投訴熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <div className="footer-statement">{FormDatatext(footer)}</div>
          </div>
          <div className="ft6"></div>
        </div>
      )}
      {isMobileTablet && (
        <div className="footer-container-mob">
          <img className="footer-icon" src={icon} alt="" />
          <div className="footer-contact-mob">
            <a href={`tel:+852${hotline}`}>
              <p>查詢熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <a href={`https://wa.me/+852${phone}`}>
              <p>Whatsapp：+852 {formatPhoneNumber(whatsapp)}</p>
            </a>
            <p>傳真號碼：{formatPhoneNumber(faxNumber)}</p>
            <h4>忠告：借錢梗要還，咪俾錢中介</h4>
            <a href={`tel:+852${hotline}`}>
              投訴熱線：+852 {formatPhoneNumber(hotline)}
            </a>
          </div>

          <div className="footer-link-mob">
            <div className="d-flex flex-column">
              <p className="footer-header-mob">常用連結</p>
              {commonLink_list.map((item, index) => (
                <div key={index} className="mt-3 text-center">
                  <HashLink smooth to={item.url}>
                    {item.title}
                  </HashLink>
                </div>
              ))}
            </div>
            <div className="d-flex flex-column">
              <p className="footer-header-mob">貸款服務</p>
              {loanServices_list.map((item, index) => (
                <div key={index} className="mt-3 text-center">
                  <HashLink smooth to={item.url}>
                    {item.title}
                  </HashLink>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5">
            {address && address2 ? (
              <>
                <p>地址1：{address}</p>
                <p>地址2：{address2}</p>
              </>
            ) : (
              <p>地址：{address || address2}</p>
            )}
            <p>營業時間：10 : 00 - 22 : 00</p>
            <p>
              電郵: {email} | Money Lenders Licence: {licence}
            </p>
          </div>
          <div className="footer-statement">{FormDatatext(footer)}</div>
        </div>
      )}
    </div>
  )
}

export default Footer
